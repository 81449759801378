// require all prototypes from the prototype file
import * as prototype from "./prototypes";
import { baseURL, baseURL1 } from "./baseURL";
import axios from "axios";
// import { Configuration, OpenAIApi } from "openai";
import OpenAI from "openai"
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import * as translate from "./convertResume";
import { store } from "./ConfigureStore";
import { json } from "react-router-dom";
import { data } from "jquery";
import { GPTTechSkillServiceFilteringFromExp, GPTPersonalSkillServiceFilteringFromExp } from "../utils/skillsService";
import { saveIntoServerCache } from "../utils/saveIntoCache";

//login loading dispatching function
export const socket_loading = () => ({
  type: prototype.SOCKET_LOADING,
});

// login failed dispatching function
export const socketio = (response) => ({
  type: prototype.SOCKET,
  payload: response,
});

//login loading dispatching function
export const total_page_loading = () => ({
  type: prototype.TOTAL_PAGE_LOADING,
});

// login failed dispatching function
export const total_page = (response) => ({
  type: prototype.TOTAL_PAGE,
  payload: response,
});

export const search_total_page_loading = () => ({
  type: prototype.SEARCH_TOTAL_PAGE_LOADING,
});

// login failed dispatching function
export const search_total_page = (response) => ({
  type: prototype.SEARCH_TOTAL_PAGE,
  payload: response,
});

//login loading dispatching function
export const login_loading = () => ({
  type: prototype.LOGIN_LOADING,
});

// login failed dispatching function
export const login_failed = (response) => ({
  type: prototype.LOGIN_FAILED,
  payload: response,
});

//login dispatching function
export const login_success = (response) => ({
  type: prototype.LOGIN_USER,
  payload: response,
});

export const clearLogin = () => async (dispatch) => {
  // localStorage.removeItem("token");
  dispatch(login_loading(true));
  dispatch(signup_loading(true));
  dispatch(existing_resume_loading(true));
  dispatch(userNameInfo_loading(true));
  dispatch(getting_cv_details_loading(true));
  dispatch(getting_profile_loading(true));
  dispatch(v_mail_loading(true));
  dispatch(r_reset_loading(true));
  dispatch(ActivitiesDetails_loading(true));
  dispatch(skillsDetails_loading(true));
  dispatch(JobtitleDetails_loading(true));
  dispatch(Professional_summary_loading(true));
  dispatch(clearStoredValue());
  dispatch(cv_id_loading(true));
  dispatch(cv_loading(true));
  dispatch(resume_step_jump_function(0));
  dispatch(secret_loading(true));
  dispatch(spanish_profile_loading(true));
  dispatch(available_jobs_loading(true));
  dispatch(getting_employer_profile_loading(true));
  dispatch(JobDetails_loading(true));
  dispatch(chat_info_loading(true));
  dispatch(end_to_end_chat_loading(true));
  dispatch(send_message_loading(true));
  dispatch(LanguageDetails_loading(true));
  dispatch(decision_loading(true));
  dispatch(notification_loading(true));
  dispatch(total_page_loading(true));
  dispatch(send_otp_loading(true));
  dispatch(verify_otp_loading(true));
  dispatch(personal_loading(true));
  dispatch(onlineUsersClear(true));
  dispatch(search_loading(true));
  // dispatch(save_link_loading(true));
  // dispatch(summaryUpdateCountLoading(true));
};

export const logOut = () => async (dispatch) => {
  dispatch(Dashboard_only_loading(0));
  fetch(baseURL + "/api/users/logout", {
    method: "POST",
    // body: JSON.stringify({ token: localStorage.getItem("token") }),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: 'include'
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log(resp.message);
      if (resp.success == true) {
        localStorage.removeItem("token");
        // window.location.assign(resp.status)
      }
    }).catch(err => {
      console.log("Logout error", err)
    });
};

// last page loading
export const last_page_loading = () => ({
  type: prototype.GET_LAST_PAGE_VISITED_LOADING,
});

export const last_page_error = (response) => ({
  type: prototype.GET_LAST_PAGE_VISITED_ERROR,
  payload: response,
});

export const last_page = (response) => ({
  type: prototype.GET_LAST_PAGE_VISITED,
  payload: response,
});

export const referralLinkLoading = (response) => ({
  type: prototype.REFERRER_LINK_LOADING,
  payload: response,
});

export const referralLink = (response) => ({
  type: prototype.REFERRER_LINK,
  payload: response,
});

// login function
// cred means the credentials for logging in (username, passowrd, acctType-> official or client)

export const signInFunction = (cred) => async (dispatch) => {
  // upon calling this fuction first dispatch a loading status into the store
  dispatch(login_loading(true));
  const mail = cred.username?.trim();
  const pass = cred.password?.trim();
  let obj = {
    username: mail,
    password: pass,
  };
  return axios
    .post(baseURL + "/api/users/signin", obj, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true
    })
    .then((resp) => {
      // console.log("login resp", resp)
      if (resp.data.data.success === true) {
        // dispatch(Dashboard_only(1));
        localStorage.setItem("token", resp.data.data.token);
        dispatch(login_success({ profile_status: resp.data.data.profile_status.profile_status, AcctType: resp.data.data.profile_status.AcctType }));
        dispatch(referralLink(resp.data.data?.referral));
        const store_value = store.getState();
        // console.log("link", store_value.userLink.message);
        // console.log("log in resp", resp);

        if (store_value.userLink.message !== null) {
          if (resp.data.data.profile_status.AcctType === "Client") {
            if (resp.data.data.profile_status.profile_status === true || resp.data.data.profile_status.profile_status === "true") {
              window.location.assign(store_value.userLink.message);
            } else if (resp.data.data.profile_status.profile_status === false || resp.data.data.profile_status.profile_status === "false") {
              window.location.assign(
                baseURL1 +
                `/user/welcome?open=true`
              );
            }
          } else if (resp.data.data.profile_status.AcctType === "Official") {
            if (resp.data.data.profile_status.profile_status === true || resp.data.data.profile_status.profile_status === "true") {
              window.location.assign(store_value.userLink.message);
            } else if (resp.data.data.profile_status.profile_status === false || resp.data.data.profile_status.profile_status === "false") {
              window.location.assign(
                baseURL1 +
                `/employer/welcome?open=true`
              );
            }
          }
        } else {
          localStorage.setItem("token", resp.data.data.token);
          if (resp.data.data.profile_status.AcctType === "Client") {
            if (resp.data.data.profile_status.profile_status === true || resp.data.data.profile_status.profile_status === "true") {
              window.location.assign(
                baseURL1 +
                `${resp.data.data.lastpage}`
              );
            } else if (resp.data.data.profile_status.profile_status === false || resp.data.data.profile_status.profile_status === "false") {
              window.location.assign(
                baseURL1 +
                `${resp.data.data.lastpage}`
              );
            }
          } else if (resp.data.data.profile_status.AcctType === "Official") {
            if (resp.data.data.profile_status.profile_status === true || resp.data.data.profile_status.profile_status === "true") {
              window.location.assign(
                baseURL1 +
                `${resp.data.data.lastpage}`
              );
            } else if (resp.data.data.profile_status.profile_status === false || resp.data.data.profile_status.profile_status === "false") {
              window.location.assign(
                baseURL1 +
                `${resp.data.data.lastpage}`
              );
            }
          }
        }
      } else {
        dispatch(login_failed("Incorrect password or username"));
      }
    })
    .catch((err) => {
      // console.log("This error occurred while logging in", err);
      dispatch(login_failed(err.response.data.data.status));
    });
};

// logout loading dispatch fucntion

export const logout_loading = () => ({
  type: prototype.LOGOUT_LOADING,
});

export const logout_success = (response) => ({
  type: prototype.LOG_OUT,
  payload: response,
});

export const returnHome = (status) => async (dispatch) => {
  localStorage.removeItem("token");
  dispatch(login_loading(true));
  dispatch(clearLogin());
  dispatch(logOut());
  window.location.assign(baseURL1);
}

// export const logout = () => async (dispatch) => {
//   dispatch(logout_loading(true));

//   return await axios
//     .post(baseURL + "/api/users/logout", {withCredentials: true})
//     .then((resp) => {
//       if (resp.success === true) {
//         localStorage.clear();
//         dispatch(logout_success(resp.message));
//         dispatch(cv_loading(true));
//         window.location.assign(resp.status);
//       }
//     })
//     .catch((err) => {
      // console.log("An error occurred while logging out");
//     });
// };

//signup loading dispatching function
export const signup_loading = () => ({
  type: prototype.SIGNUP_LOADING,
});

// login failed dispatching function
export const signup_failed = (response) => ({
  type: prototype.SIGNUP_FAILED,
  payload: response,
});

//login dispatching function
export const signup_success = (response) => ({
  type: prototype.SIGNUP_USER,
  payload: response,
});

// signup function
// cred means the credentials for signing in (username, passowrd, acctType-> official or client)

export const signUpFunction = (cred) => async (dispatch) => {
  // upon calling this fuction first dispatch a loading status into the store
  dispatch(signup_loading(true));
  const mail = cred.username?.trim();
  const pass = cred.password?.trim();
  const code = cred.code !== "null" && cred.code !== null ? cred.code?.trim() : "";
  const obj = {
    email: mail,
    password: pass,
    AcctType: cred.AcctType,
    code: code
  };
  // console.log(obj);
  return await axios
    .post(baseURL + "/api/users/signup", obj, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((resp) => {
      // console.log("signing in resp", resp);
      if (resp.data.success === true) {
        // store the authentication token value inside the localstorage got he browser.
        // dispatch(Dashboard_only(1));
        dispatch(signup_success(resp.data));
      }
    })
    .catch((err) => {
      // console.log("This error occurred while signing in", err);
      dispatch(signup_failed(err.response.data.message));
    });
};

// google auth section for both user and officials

export const userGoogleAuth = () => async (dispatch) => {
  // open the auth route
  window.open(baseURL + "/api/auth/google", "_self");
};

// official google auth function

export const officialGoogleAuth = () => async (dispatch) => {
  // open the auth route
  window.open(baseURL + "/api/auth/google2", "_self");
};

// call this funcion to get signed in user authorization token

export const googleSuccess = () => async (dispatch) => {
  // dispatch(login_success({ profile_status: token }));
  let url = baseURL + `/api/auth/success?code=${localStorage.getItem("ref")}`;
  await axios.get(url, { withCredentials: true})
    .then(resp => {
      // console.log("data data", resp);
      if (resp.data.success == true) {
        localStorage.removeItem("ref");
        // dispatch(Dashboard_only(1));
        dispatch(login_success({ profile_status: resp.data.profile_status, AcctType: resp.data.AcctType }));
        localStorage.setItem("token", resp.data.token);
        dispatch(referralLink(resp.data.data?.createRef?.link));
        if (resp.data.lastpage) {
          // dispatch(last_page(`${resp.data.lastpage}`))
          // window.location.assign(baseURL1 + `${resp.data.lastpage}`);
        }
      }
    }).catch(err => {
      // console.log("err", err);
      if (err.response?.data.success == false) {
        window.location.assign(baseURL1 + "/");
      }
    });
};

// linkedin auth section for both user and officials

export const userLinkedinAuth = () => async (dispatch) => {
  // open the auth route
  window.open(baseURL + "/api/auth/linkedin", "_self");
};

// official google auth function

export const officialLinkedinAuth = () => async (dispatch) => {
  // open the auth route
  window.open(baseURL + "/api/auth/linkedin2", "_self");
};

// cv section
export const cv_loading = () => ({
  type: prototype.CV_LOADING,
});

export const cv_failed = (response) => ({
  type: prototype.GENERATING_CV_FAILED,
  payload: response,
});

export const cv_success = (response) => ({
  type: prototype.CV_GENERATED_SUCCESSFULLY,
  payload: response,
});

// default credentials
// on clickng Done btn, call this function here.
export const return_CV_state_back_to_null = () => (dispatch) => {
  dispatch(cv_loading(true));
  dispatch(existing_resume_loading(true));
  dispatch(cv_id_loading(true));
};

export const Personal_CV_state_back_to_null = () => (dispatch) => {
  dispatch(cv_loading(true));
};

export const spanish_loading = () => ({
  type: prototype.SPANISH_LOADING,
});

export const spanish_success = (spanish) => ({
  type: prototype.SPANISH,
  payload: spanish,
});

export const dispatchSpanish = (arg) => async (dispatch) => {
  dispatch(spanish_loading(true));
  dispatch(spanish_success(arg));
};

export const Professional_summary_loading = () => ({
  type: prototype.PROFESSIONAL_SUMMMARY_LOADING,
});

export const Professional_summary_failed = (err) => ({
  type: prototype.PROFESSIONAL_SUMMMARY_FAILED,
  payload: err
});

export const clearPS = () => (dispatch) => {
  dispatch(Professional_summary_loading(true));
};
export const Professional_summary = (summary) => ({
  type: prototype.PROFESSIONAL_SUMMMARY,
  payload: summary,
});
export const summaryUpdateCountLoading = () => ({
  type: prototype.COUNT_LOADING
})
export const summaryUpdateCount = () => ({
  type: prototype.COUNT
})

//    command = `Based on the provided experience list '${exp_to_use?.join(', ')}', technical skills '${actual_skill?.join(', ')}', and the desired job type '${cred_json_CvType}', create a concise professional summary suitable for a resume.The returned response should be in this laguage ${cred.Language}. Limit the summary to 70 words and format it as JSON with the key "Summary".`;

// update
// Based on the provided experience list '${exp_to_use?.join(', ')}', technical skills '${actual_skill?.join(', ')}', brief summary ${cred.summary} and the desired job type '${cred_json_CvType}', create a concise professional summary suitable for a resume. The returned response should be in this laguage ${cred.Language}. Limit the summary to 70 words and format it as JSON with the key "Summary".

// generate professional summary
export const generate_professional_summary = (cred) => async (dispatch) => {
  const exp_to_use = [];

  for (let i = 0; i <= cred.Experience?.length - 1; i++) {
    exp_to_use.push(cred.Experience[i]?.Day_to_day_work);
  }
  const cred_json_exp = cred.Experience;
  let actual_skill = [];
  if (cred.Technical_skills !== null && cred.Technical_skills !== undefined) {
    if (cred.Technical_skills.length !== 0) {
      for (let i = 0; i <= cred.Technical_skills.length - 1; i++) {
        // console.log(typeof cred.Technical_skills[i]);
        if (typeof cred.Technical_skills[i] === "object") {
          actual_skill.push(cred.Technical_skills[i]?.value);
        } else if (typeof cred.Technical_skills[i] === "string") {
          actual_skill.push(cred.Technical_skills[i]);
        }
      }
    } else {
      actual_skill = [];
    }
  } else {
    actual_skill = [];
  }
  const cred_json_tech = cred.Technical_skills;

  const cred_json_CvType = JSON.stringify(cred.CvType);
  // console.log("cred", cred);
  let command;
  if (cred.summary) {
    command = `Given the summary ${cred.summary} and the desired job type '${cred_json_CvType}, craft a professional summary tailored for a resume. This summary should emphasize the candidate's qualifications for a job in '${cred_json_CvType}'. The response must be in '${cred.Language}' and limited to 70 words. Format the output as JSON with the key "Summary".`;
  } else {
    command = `Based on the provided experience list '${exp_to_use?.join(', ')}', technical skills '${actual_skill?.join(', ')}', and the desired job type '${cred_json_CvType}', create a concise professional summary suitable for a resume.The returned response should be in this laguage ${cred.Language}. Limit the summary to 70 words and format it as JSON with the key "Summary".`;
  }
  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
  await openai.chat.completions.create({
    model: "gpt-4o", // gpt-3.5-turbo
    messages: [{ role: "user", content: command }],
    temperature: 0.9,
  })
    .then((resp) => {
      const result = resp.choices[0].message.content;
      let strip = result.slice(result.indexOf("{"), result.lastIndexOf("}") + 1);
      let obj = strip;
      // let obj = result;
      // console.log("obj", obj);
      try {
        obj = JSON.parse(obj);
      } catch (error) {
        if (error instanceof SyntaxError) {
          // console.log("error", error);
          dispatch(generate_professional_summary(cred));
        } else {
          // console.error('Unexpected error:', error.message);
        }
      }
      if (typeof obj == "object") {
        const keys = Object.keys(obj);
        let f_result;
        if (keys[0].slice(0, 1) !== keys[0].slice(0, 1).toUpperCase()) {
          const r = {
            Summary: obj.summary,
          };
          f_result = r.Summary;
        } else {
          f_result = obj.Summary;
        }
        saveIntoServerCache("summary", f_result);
        dispatch(Professional_summary(f_result));
      }

    }).catch((error) => {
      // console.log("error", error);
      if (error.message == "Network Error") {
        // retry the function
        dispatch(Professional_summary_failed("Network Error"));
      }
    });
};

// generate experience based on provided experience

// individual experience
export const expDetails = (exp) => ({
  type: prototype.INDIVIDUAL_EXPERIENCE,
  payload: exp,
});

export const expDetails_error = (error) => ({
  type: prototype.INDIVIDUAL_EXPERIENCE_ERROR,
  payload: error,
});

export const expDetails_loading = () => ({
  type: prototype.INDIVIDUAL_EXPERIENCE_LOADING,
});

export const cleanEX = () => (dispatch) => {
  dispatch(expDetails_loading(true));
};

// `Given the user's experience at ${cred.workPlace} company, denoted as ${cred.Experience}, in a ${cred.CvType} role, craft a succinct experience description tailored for a ${cred.CvType} job application. Also, hightlight top 10 ATS friendly skill in ${cred.CvType} field. Identify and emphasize synergies. The response should solely consist of the experience description, devoid of any introductory or concluding text, and must be confined around 50 words. If applicable, present the experience in bullet format. The result should be in the same language type received i.e if its in Spanish, return in Spanish and if its in English return in English. return result should be in this format.
//   {
//     workPlace: ${cred.workPlace}
//     CvType: ${cred.CvType},
//     Technical_skills: [skills 1, skills 2, skills 3, skills 4, skills 5],
//     Personal_skills: [skills 1, skills 2, skills 3, skills 4, skills 5],
//     Experience: "The generated experience description result goes here",
//   }`;

//   const command = `Based on the user's input ${cred.Experience} and job title '${cred.CvType}', irrespective of its length or grammatical precision, craft a professional and coherent experience description. The user has worked at '${cred.workPlace}' in a '${cred.CvType}' role. Leverage this information, along with any other related details, to highlight relevant skills and experiences, especially those that align with top ATS-friendly technical and personal skills in the '${cred.CvType}' field. Prioritize clarity and succinctness, aiming for a 75-word description, which should be in bullet format. The final output should not include any introductory or extraneous text. Organize the result as follows:
// {
//     workPlace: '${cred.workPlace}',
//     CvType: '${cred.CvType}',
//     Technical_skills: ['skill 1', 'skill 2', ..., 'skill 5'],
//     Personal_skills: ['skill 1', 'skill 2', ..., 'skill 5'],
//     Experience: "..."
// }`;

// const command = `Transform and enhance the user's input (${cred.Experience}) and job title (${cred.CvType}) into a professional, bullet-pointed experience description suitable for a resume, ensuring the entire response, including the experience description and the listing of technical and personal skills, min 60 words and does not exceed 200 words in total. The response must be in the same language as the input provided in ${cred.Experience} to ensure linguistic coherence. This enhancement should cater specifically to someone in the '${cred.CvType}' role, effectively highlighting and enriching relevant tasks and responsibilities. Additionally, identify and list the top 5 technical and personal skills necessary for the '${cred.CvType}' field, optimizing these for ATS compatibility and impact within the word limit. Please exclude any introductory or unrelated content, and organize the output for easy reading and application on a resume, thereby enhancing the overall presentation. It's imperative that this output strictly adheres to the primary language used in the provided input (${cred.Experience}), aligning with the linguistic requirements and ensuring the content is fully tailored and accessible within the 200-word constraint. Organize the transformed results in json readable format strictly following this key: Experience, Technical_skills (array), Personal_skills (array)`;

// The description should range between 200-500 characters.
export const generate_experience = (cred) => async (dispatch) => {
  // console.log("cred", cred);
  const command = `Transform and enhance the user's input (${cred.Experience}) into a detailed professional experience suitable for a resume, optimized for Applicant Tracking Systems (ATS), and tailored to the job title (${cred.CvType}). Include relevant skills and responsibilities for the role (${cred.CvType}) to fill in gaps, ensuring the experience description is coherent and presented in not more than 3 to 4 bullet points to highlight skills and abilities. Each points should be on a new line.

Additionally, identify and list the top 5 technical and personal skills relevant to the role, incorporating ATS-friendly keywords to further optimize the resume for ATS systems.
Return the result in the user's selected input language (${cred.Language}).
Organize the output in JSON as follows:

{
    "Experience": "Detailed professional experience enhancement in bullet points",
    "CvType": "Specified job title",
    "Technical_skills": ["Skill 1", "Skill 2", ..., "Skill 5"],
    "Personal_skills": ["Skill 1", "Skill 2", ..., "Skill 5"]
}
`;

  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
  await openai.chat.completions.create({
    model: "gpt-4o",
    messages: [{ role: "user", content: command }],
    temperature: 0.9,
    response_format: { type: "json_object" }
  })
    .then((resp) => {
      const result = resp.choices[0].message.content;
      // console.log("GPT result", result)
      // let r3 = result.replace("\n\n", "");
      // let r2 = r3.replace("\n", "");
      let strip = result.slice(result.indexOf("{"), result.lastIndexOf("}") + 1);
      let r1 = strip;
      try {
        r1 = JSON.parse(r1);
      } catch (error) {
        if (error instanceof SyntaxError) {
          dispatch(generate_experience(cred));
        } else {
          // console.error("Unexpected error:", error.message);
        }
      }
      if (typeof r1 == "object") {
        if (localStorage.getItem("count") !== null && localStorage.getItem("count") == 1) {
          dispatch(GPTTechSkillServiceFilteringFromExp(r1.Technical_skills));
          dispatch(GPTPersonalSkillServiceFilteringFromExp(r1.Personal_skills));
        }
        // console.log("r1.Experience", r1.Experience)
        let experienceText;
        // console.log("typeof r1.Experince", typeof r1.Experince)
        if (Array.isArray(r1.Experience) == true) {
          experienceText = r1.Experience.join("\n");
        } else {
          experienceText = r1.Experience;
        }
        // console.log("experienceText", experienceText);
        // dispatch experience
        dispatch(expDetails(experienceText));
        // return result;
      }
    })
    .catch((err) => {
      // console.log("experience generation error:", err.message);
      if (
        err.message == "Network Error" ||
        err.message == "timeout exceeded" ||
        err.message == "Request failed with status code 503"
      ) {
        dispatch(expDetails_error("Network error"));
      }
    });
};

export const lang_type = (cred) => ({
  type: prototype.LANG_TYPE_S,
  payload: cred,
});

// generate resume function
export const generate_my_cv = (cred) => async (dispatch) => {
  dispatch(cv_loading(true));
  // console.log("My credentials", cred)
  if (Object.keys(cred).length == 0) {
    // dispatch error message
    dispatch(cv_failed("No credentials"));
  } else {
    // console.log("cred", cred);
    const final_obj = { ...cred, Lang: [{ lang: "English" }] };
    dispatch(cv_success(final_obj));
  }
};

// dispatch the id selected to store
export const cv_id_loading = () => ({
  type: prototype.CV_ID_LOADING,
});

export const dispatch_cv_id = (id) => ({
  type: prototype.CV_ID,
  payload: id,
});

export const Cv_id = (id) => (dispatch) => {
  dispatch(dispatch_cv_id(id.id));
};

export const dispatch_cv_pics = (pics) => ({
  type: prototype.CV_PICS,
  payload: pics,
});

// Existing resume section

export const user_CV_state_back_to_null = () => (dispatch) => {
  dispatch(existing_resume_loading(true));
};

export const existing_resume_loading = () => ({
  type: prototype.EXISTING_CV_LOADING,
});
export const existing_resume_failed = (response) => ({
  type: prototype.GENERATING_EXISTING_CV_FAILED,
  payload: response,
});
export const existing_resume = (response) => ({
  type: prototype.EXISTING_CV_GENERATED_SUCCESSFULLY,
  payload: response,
});

// prefill existing resume with the selected resume cliet selected from  the list of resume they already have with use which is in the saved_cv state.
// start

// end

const commandFunction = (text) => {
  const command = `You are an HR career specialist with experience in resume creation and improvement. Your goal is to capture candidate information provided here '${text}' and divide it into the following keys: "Fullname, Address, Email, Phone_number, Professional_summary, Linkedin, Website, School, Degree, Start_date, End_date,  Volunteer_Org, Membership_level, Volunteer_join_date, Volunteer_end_date, Language, Language_speaking_rate, Interest, Award, Award_name, Award_giver, Award_year, Education, Experience, Technical_skills, Personal_skills, Education should be divided into the following key "School, Degree, End_date, Start_date" and Experience should be divided into the following key "Where_i_worked, Where_i_worked_location, My_job_title, Work_start_date, Work_end_date, Day_to_day_work" anf Reference should be divide into the following keys: Reference_person_name, How_do_i_know_this_person, Referencee_job_title, Reference_email, Reference_phone_number, only, Education (array), Experience (array), Award (array), and Technical_skills (array), Personal_skills(array), Reference(array)' respectively. Remove all nested keys inside Technical_skills and Personal_skills, only put the values in an Technical _skills array and Personal_skills array. Strictly follow each key above"

  if any of the text provided here ${text} is in bullet or numbering form, return them in that same form.
   Abbreviate all months.
   Arrange the Experience and Education in the standard and professional resume date arrangement.
   Check the content for grammar, spelling, and punctuation errors, and ensure the final resume is error-free.
   if adjustment is needed in the course of parsing the response, make the adjustment and return the response or result exactly in object-readable format following this key:
   If the text is too long to process all, then provide the results within your character limit capabilities.
   Do anything necessary to avoid getting this kind of error 'chatGPT error SyntaxError: Unterminated string in JSON' and ensure to always close any open parenthesis '()', square bracket '[]' and curly brackets '{}' before returning the result or response.
   Do not add any text before or after the object result. Only return the object result itself.
 `;
  return command;
};

export const existing_resume_features = (arg) => async (dispatch) => {
  dispatch(existing_resume_loading(true));
  const bearer = localStorage.getItem("token");
  // console.log("arg", arg)
  return fetch(baseURL + "/api/extract/existingPDF", {
    method: "POST",
    body: arg,
    // headers: {
    //   "Content-Type": "application/json",
    //   // Authorization: "Bearer " + bearer,
    // },
    credentials: "include"
  })
  .then((resp) => resp.json())
  .then(resp => {
    // console.log("extracted", resp)
    if (resp.success == true && resp.data !== null) {
      dispatch(existing_resume(resp.data));
      let store_value = store.getState();
      let profile = store_value.profile.message;
      if (profile ==  null) {
        window.open(baseURL1 + "/form", "_self");
      } else {
        window.open(baseURL1 + "/generateCV", "_self");
      }
    } else {
      // console.log(resp);
      dispatch(existing_resume_failed("Please try again."))
    }
  }).catch(err => console.log("error", err))
};

/* const r1 = resp.text.replace("\n", "");
console.log("r1", r1)
const r2 = JSON.parse(r1);
console.log("r2", r2);
if (typeof r2 === 'object') {
  dispatch(existing_resume(r2));
} else {
  dispatch(existing_resume_failed("Failed to extract your information from the CV you provided. Try again by uploading it again or proceed to fill the form ahead. Thanks"))
} */

// get user's name to be used in dashboard

export const userNameInfo_loading = () => ({
  type: prototype.LOADING_USER_NAME,
});

export const userInfo = (response) => ({
  type: prototype.USER_NAME_DB,
  payload: response,
});

export const userName_from_db = () => async (dispatch) => {
  dispatch(userNameInfo_loading(true));

  return fetch(baseURL + "/api/users/myInfo", {
    headers: {
      "Content-Type": "application/json"
      // Authorization: "Bearer " + params,
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("user information", resp);
      if (resp.success === true) {
        dispatch(userInfo(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")){
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => {
      // console.log("getting user name error", err);
    });
};

export const save_last_resume = (response) => ({
  type: prototype.LAST_RESUME,
  payload: response,
});

export const lastResme = (cred) => async (dispatch) => {
  dispatch(save_last_resume(cred));
};

export const getting_cv_details_loading = () => ({
  type: prototype.GETTING_CV_LOADING,
});

export const getting_failed = (response) => ({
  type: prototype.GETTING_CV_FAILED,
  payload: response,
});

export const getting_successful = (response) => ({
  type: prototype.GETTING_CV_SUCCESS,
  payload: response,
});

export const getAllMyCv = (token) => async (dispatch) => {
  return fetch(baseURL + "/api/resumeUpload/mycv", {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token,
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        dispatch(getting_failed(resp.status));
      }
    })
    .catch((err) => {
      /* console.log("error while fetching cvs from database");    
    const errArr = err.message.split(" ");
    // console.log("err mes", err.message)
    if (errArr[0] === "Failed" && errArr[1] === "to" && errArr[2] === "fetch") {
      alert("Session expired, login again.");
      window.open(baseURL1 + "/login");
    } else {
      dispatch(getting_failed(err));
    } */

      dispatch(getting_failed(err));
    });
};

// empployer start

export const getting_employer_profile_loading = () => ({
  type: prototype.GET_EMPLOYER_PROFILE_LOADING,
});

export const getting_employer_profile_failed = (response) => ({
  type: prototype.GET_EMPLOYER_PROFILE_FAILED,
  payload: response,
});

export const getting_employer_profile_successful = (response) => ({
  type: prototype.GET_EMPLOYER_PROFILE_SUCCESS,
  payload: response,
});

export const dispatch_employer_employee_info_loading = (response) => ({
  type: prototype.EMPLOYER_EMPLOYEEINFO_LOADING,
});

export const dispatch_employer_employee_info = (response) => ({
  type: prototype.EMPLOYER_EMPLOYEEINFO,
  payload: response,
});

export const getEmployerProfilePublicView =
  (employerProfileId) => async (dispatch) => {
    return fetch(
      baseURL +
      `/api/employer/employer_profile_public_view/${employerProfileId}`,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + localStorage.getItem("token"),
        },
        credentials: "include"
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log("employer profile", resp);

        if (resp.success === true) {
          if (resp.data === null) {
            const response = [];
            dispatch(getting_profile_successful(response));
          } else {
            dispatch(getting_employer_profile_successful(resp.data));
          }
        } else if (resp.success === false&& (resp.message == "Unauthorized" || resp.message == "No token provided")) {
          dispatch(returnHome(resp.status));
        } else {
          dispatch(getting_employer_profile_failed(resp.status));
        }
      })
      .catch((err) => {
        // console.log("error while fetching cvs from database", err);
        dispatch(getting_employer_profile_failed(err));
      });
  };

// employer ends

// save link
export const save_link_loading = (response) => ({
  type: prototype.SAVE_LINK_LOADING,
});

export const savelink = (response) => ({
  type: prototype.SAVE_LINK,
  payload: response,
});

export const getting_profile_loading = () => ({
  type: prototype.GET_PROFILE_LOADING,
});

export const getting_profile_failed = (response) => ({
  type: prototype.GET_PROFILE_FAILED,
  payload: response,
});

export const getting_profile_successful = (response) => ({
  type: prototype.GET_PROFILE_SUCCESS,
  payload: response,
});

export const getProfile = (params) => async (dispatch) => {
  // dispatch(getting_profile_loading(true));
  return fetch(baseURL + "/api/client/myprofile", {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + params,
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("profile resp", resp);

      if (resp.success === true) {
        if (resp.data === null) {
          const response = [];
          dispatch(getting_profile_successful(response));
        } else {
          const store_value = store.getState();
          let profile_stat = store_value.login.profile_status;
          if (
            profile_stat.profile_status === "false" ||
            profile_stat.profile_status === false
          ) {
            profile_stat.profile_status = "true";
            dispatch(login_success(profile_stat));
          }
          dispatch(getting_profile_successful(resp.data?.profile));
          dispatch(userInfo(resp.data?.user));
        }
      } else if (resp.success == false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
          dispatch(returnHome(resp.status));
      } else {
        // console.log("error profile", resp)
        dispatch(getting_profile_failed(resp.message));
      }
    })
    .catch((err) => {
      // console.log("error while fetching cvs from database", err);
      dispatch(getting_profile_failed(err));
    });
};

export const updateProfileLanguage = (lang) => async (dispatch) => {
  // console.log("lang", lang);
  const obj = {
    language: lang
  }
  return fetch(baseURL + "/api/client/updateLanguage", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
  .then((resp) => resp.json())
  .then((resp) => {
    if (resp.success === true) {
      dispatch(getting_profile_successful(resp.data));
    }  else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
      dispatch(returnHome(resp.status));
    }
  })
  .catch((err) => console.log("Update failed"));
}

export const updatePersonalInfo = (cred) => async (dispatch) => {
  // make an update api call
  const obj = {
    FirstName: cred.Firstname,
    LastName: cred.LastName,
    Address: cred.Address,
    Email: cred.Email,
    Phone_number: cred.Phone_number,
    JobInterests: cred.jobInterest,
  };
  return fetch(baseURL + "/api/client/updatepersonalInfo", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      }  else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

export const uppdateYOF = (yof) => async (dispatch) => {
  // make an update api call
  const obj = {
    Years_of_experience: yof,
  };
  fetch(baseURL + "/api/client/updateyearofexperience", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      }  else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } 
    })
    .catch((err) => console.log("Update failed"));
};

export const uppdateJobTitle = (title) => async (dispatch) => {
  // make an update api call
  const obj = {
    Job_title: title,
  };
  fetch(baseURL + "/api/client/updatejobtitle", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

export const uppdateAbout = (about) => async (dispatch) => {
  // make an update api call
  const obj = {
    About: about,
  };
  return fetch(baseURL + "/api/client/updateabout", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

export const uppdateInterest = (interest) => async (dispatch) => {
  // make an update api call
  const obj = {
    Interest: interest,
  };
  return fetch(baseURL + "/api/client/updateinterest", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

export const updateExperience = (experience) => async (dispatch) => {
  // make an update api call
  // console.log("workkkkk", experience);
  const obj = {
    Experience: experience,
  };
  return fetch(baseURL + "/api/client/updateexperience", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

export const updateEducation = (education) => async (dispatch) => {
  // make an update api call
  const obj = {
    Education: education,
  };
  return fetch(baseURL + "/api/client/updateeducation", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

export const updateRef = (reference) => async (dispatch) => {
  // make an update api call
  const obj = {
    Reference: reference,
  };
  return fetch(baseURL + "/api/client/updatereference", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

export const updateSkills = (skills) => async (dispatch) => {
  // make an update api call
  const obj = {
    Skills: skills,
  };
  return fetch(baseURL + "/api/client/updateskills", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

export const updateSoft_skills = (soft_skills) => async (dispatch) => {
  // make an update api call
  const obj = {
    Soft_skills: soft_skills,
  };
  return fetch(baseURL + "/api/client/updatesoftskills", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(getting_profile_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log("Update failed"));
};

// function to save users cv details to the backend
export const SiteError = (response) => ({
  type: prototype.ERROR,
  payload: response,
});
export const SiteErrorLoading = () => ({
  type: prototype.ERROR_LOADING,
});

export const Click = (response) => ({
  type: prototype.CLICK,
  payload: response,
});
export const ClickLoading = () => ({
  type: prototype.CLICK_LOADING,
});

export const saveCvDetails =
  (details, id, clientSecret, languageType) => async (dispatch) => {
    // dispatch(getting_profile_loading(true));
    // console.log("posting users resume details");
    const token = localStorage.getItem("token");
    // do something here with the details argument.
    const value = { ...details, pdfId: id, secret: clientSecret, languageType: languageType };
    return fetch(baseURL + "/api/resumeUpload/saveCV", {
      method: "POST",
      body: JSON.stringify(value),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
      },
      credentials: "include"
    })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("CV successfully saved", resp);
      // console.log("statuscode", resp.statusCode);
      if (resp.statusCode == 200 && resp.success == true) {
        if (resp.data.employee !== null) {
          let store_value = store.getState();
          let profile = store_value.profile.message;
          if (profile == null) {
            dispatch(getting_profile_successful(resp.data.employee));
            dispatch(login_success({ profile_status: true, AcctType: "Client" })); // login to indicate that the user now have a profile.
            dispatch(Click(true));
          }
        }
        if (resp.data.userResume !== null && resp.data.userResume !== undefined) {
          dispatch(getting_successful(resp.data.userResume));
          dispatch(Click(true));
        } else {
          dispatch(getting_successful([]));
        }
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("statuscode", resp);
        if (resp.statusCode == 400 || (resp.statusCode == undefined && resp.title == "Error.")) {
          // dispatch error message.
          dispatch(SiteError("Network Error, please try again."));
        }
        if (resp.statusCode == undefined && (resp.title == "Exist Error." || resp.title == "Resume not saved.")) {
          // console.log("Got here")
          dispatch(SiteError(resp.message));
        }
      }
    })
    .catch((err) => {
      // console.log("resume error catch: ", err);
      throw err;
    });
  };

export const v_mail_loading = () => ({
  type: prototype.V_MAIL_LOADING,
});

export const v_mail_failed = (response) => ({
  type: prototype.V_MAIL_FAILED,
  payload: response,
});

export const v_mail_success = () => ({
  type: prototype.V_MAIL_SUCCESS,
});

export const sendMail = (mail) => async (dispatch) => {
  dispatch(v_mail_loading(true));

  const obj = {
    username: mail,
  };
  fetch(baseURL + "/api/users/yourMail", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("Mail sent for verification", resp);
      if (resp.success === true) {
        dispatch(v_mail_success(resp.status));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        dispatch(v_mail_failed(resp.message));
      }
    })
    .catch((err) => {
      // console.log("an error occured", err);
      dispatch(v_mail_failed(err));
    });
};

export const r_reset_loading = () => ({
  type: prototype.R_SET_LOADING,
});

export const r_reset_failed = (response) => ({
  type: prototype.R_SET_FAILED,
  payload: response,
});

export const r_reset_success = (response) => ({
  type: prototype.R_SET_SUCCESS,
  payload: response,
});

export const reset_password = (cred) => async (dispatch) => {
  dispatch(r_reset_loading(true));
  const obj = {
    newPassword: cred.password,
    confirmPassword: cred.confirmPassword,
    userId: cred.userId,
  };

  fetch(baseURL + "/api/users/forgetPassword", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("password changed successfully", resp);
      if (resp.success === true) {
        dispatch(r_reset_success(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        dispatch(r_reset_failed(resp.status));
      }
    })
    .catch((err) => {
      // console.log("An error occurred while setting a new password.");
      dispatch(r_reset_failed(err));
    });
};

export const delete_a_resume = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const obj = {
    resumeId: id,
  };
  fetch(baseURL + "/api/resumeUpload/delresume", {
    method: "DELETE",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token,
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Resume deleted", resp);
        dispatch(getting_successful(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("An error occurred while deleting a cv", resp.message);
      }
    })
    .catch((err) => {
      // console.log("An error occurred while deleting a cv", err);
    });
};

export const ActivitiesDetails = (activities) => ({
  type: prototype.ACTIVITIES,
  payload: activities,
});

export const ActivitiesDetails_loading = () => ({
  type: prototype.ACTIVITIES_LOADING,
});

// dispatch activities into the store
export const dispatchActivites = (activities) => (dispatch) => {
  dispatch(ActivitiesDetails(activities));
};

export const skillsDetails = (skills) => ({
  type: prototype.SKILLS,
  payload: skills,
});

export const skillsDetails_loading = () => ({
  type: prototype.SKILLS_LOADING,
});

// dispatch skills into the store
export const dispatchSkills = (skills) => (dispatch) => {
  dispatch(skillsDetails(skills));
};

export const p_skillsDetails = (pskills) => ({
  type: prototype.PERSONAL_SKILLS,
  payload: pskills,
});

export const p_skillsDetails_loading = () => ({
  type: prototype.PERSONAL_SKILLS_LOADING,
});

// dispatch skills into the store
export const dispatchPskills = (pskills) => (dispatch) => {
  dispatch(p_skillsDetails(pskills));
};

export const JobtitleDetails = (jobtitle) => ({
  type: prototype.JOBTITLE,
  payload: jobtitle,
});

export const JobtitleDetails_loading = () => ({
  type: prototype.JOBTITLE_LOADING,
});

// dispatch job title into the store
export const dispatchJobtitle = (Jobtitle) => (dispatch) => {
  dispatch(JobtitleDetails(Jobtitle));
};

export const EducationDetails = (education) => ({
  type: prototype.EDUCATION,
  payload: education,
});

export const EducationDetails_loading = () => ({
  type: prototype.EDUCATION_LOADING,
});

// dispatch activities into the store
export const dispatchEducation = (education) => (dispatch) => {
  dispatch(EducationDetails(education));
};

export const VoluntaryDetails = (voluntary) => ({
  type: prototype.VOLUNTARY,
  payload: voluntary,
});

export const VoluntaryDetails_loading = () => ({
  type: prototype.VOLUNTARY_LOADING,
});

// dispatch activities into the store
export const dispatchVoluntary = (voluntary) => (dispatch) => {
  dispatch(VoluntaryDetails(voluntary));
};

export const AwardDetails = (award) => ({
  type: prototype.AWARD,
  payload: award,
});

export const AwardDetails_loading = () => ({
  type: prototype.AWARD_LOADING,
});

// dispatch activities into the store
export const dispatchAward = (award) => (dispatch) => {
  dispatch(AwardDetails(award));
};

export const ReferenceDetails = (ref) => ({
  type: prototype.REFERENCE,
  payload: ref,
});

export const ReferenceDetails_loading = () => ({
  type: prototype.REFERENCE_LOADING,
});

// dispatch activities into the store
export const dispatchReference = (ref) => (dispatch) => {
  dispatch(ReferenceDetails(ref));
};

export const LanguageDetails = (lang) => ({
  type: prototype.LANGUAGE,
  payload: lang,
});

export const LanguageDetails_loading = () => ({
  type: prototype.LANGUAGE_LOADING,
});

// dispatch activities into the store
export const dispatchLanguage = (lang) => (dispatch) => {
  dispatch(LanguageDetails(lang));
};

export const LanguageTranslate = (lg) => ({
  type: prototype.LANGUAGE_TRANSLATION,
  payload: lg,
});

// export const LanguageTranslate_loading = () => ({
//   type: prototype.LANGUAGE_TRANSLATION_LOADING,
// });

// dispatch activities into the store
export const dispatchT_Language = (lg) => (dispatch) => {
  dispatch(LanguageTranslate(lg));
};

export const clearStoredValue = () => (dispatch) => {
  dispatch(LanguageDetails_loading(true));
  dispatch(ActivitiesDetails_loading(true));
  dispatch(skillsDetails_loading(true));
  dispatch(JobtitleDetails_loading(true));
  dispatch(EducationDetails_loading(true));
  dispatch(AwardDetails_loading(true));
  dispatch(ReferenceDetails_loading(true));
  dispatch(p_skillsDetails_loading(true));
  dispatch(personal_loading(true));
};

export const secret_loading = () => ({ // resume secret 
  type: prototype.SECRET_LOADING,
});

export const save_secret_success = (err) => ({
  type: prototype.SECRET,
  payload: err,
});

export const save_secret = (secret) => async (dispatch) => {
  dispatch(save_secret_success(secret));
};

export const personal_loading = () => ({
  type: prototype.PERSONAL_INFO_LOADING,
});

export const personal_failed = (err) => ({
  type: prototype.PERSONAL_INFO_FAILED,
  payload: err,
});

export const personal_info = (info) => ({
  type: prototype.PERSONAL_INFO,
  payload: info,
});

export const personal_information = (info) => async (dispatch) => {
  // dispatch(personal_loading(true));

  // perform a posting save to the database here

  dispatch(personal_info(info));
};

// initailize start
// function to save users cv details to the backend
export const save_personal_details =
  (details, id, clientSecret) => async (dispatch) => {
    const token = localStorage.getItem("token");
    // do something here with the details argument.
    const value = {
      Fullname: details.Firstname + " " + details.LastName,
      FirstName: details.Firstname,
      LastName: details.LastName,
      Address: details.Address,
      Email: details.Email,
      Phone_number: details.Phone_number,
      Secret: clientSecret,
      jobInterests: details.jobInterest,
      method: details.method
    };
    return fetch(baseURL + "/api/temp/initializeRes", {
      method: "POST",
      body: JSON.stringify(value),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
      },
      credentials: "include"
    })
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log("Personal information saved successfully.", resp);
        if (resp.success === true) {
          if (resp.data !== null) {
            dispatch(userInfo(resp.data.user));
            dispatch(personal_info(details));
            dispatch(getting_profile_successful(resp.data.client));

            dispatch(login_success({ profile_status: resp.data.profile_status, AcctType: resp.data.user.AcctType }));
          }
        } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
          dispatch(returnHome(resp.status));
        } else {
          dispatch(personal_failed(resp.message));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

export const saveEducation = (education, secret) => async (dispatch) => {
  // make an update api call
  const obj = {
    Secret: secret,
    Education: education
  };
  return fetch(baseURL + "/api/temp/initializeEducation", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Initialize education updated successfully.", resp);
        dispatch(getting_profile_successful(resp.data.client));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log(err));
};

export const saveExperience = (experience, secret) => async (dispatch) => {
  // make an update api call
  const obj = {
    Secret: secret,
    Experience: experience,
  };
  return fetch(baseURL + "/api/temp/initializeExperience", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Initialize experience updated successfully.", resp);
        dispatch(getting_profile_successful(resp.data.client));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log(err));
};

export const saveLanguage = (language, secret) => async (dispatch) => {
  // make an update api call
  const obj = {
    Secret: secret,
    Language: language,
  };
  return fetch(baseURL + "/api/temp/initializeLanguage", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Initialize language updated successfully.", resp);
        dispatch(getting_profile_successful(resp.data.client));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log(err));
};

export const saveSkills = (t_skills, p_skills, secret, language) => async (dispatch) => {
  // make an update api call
  const obj = {
    Secret: secret,
    Technical_skills: t_skills,
    Personal_skills: p_skills,
    language: language
  };
  return fetch(baseURL + "/api/temp/initializeSkills", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Initialize skills updated successfully.", resp);
        dispatch(getting_profile_successful(resp.data.client));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log(err));
};

export const saveJobAndSum =
  (title, summary, YOF, secret) => async (dispatch) => {
    // make an update api call
    const obj = {
      Secret: secret,
      CvType: title,
      Professional_summary: summary,
      YOF: YOF,
    };
    return fetch(baseURL + "/api/temp/initializeTypeSum", {
      method: "PUT",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
      credentials: "include"
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success === true) {        
          // console.log(
          //   "Initialize job type and professional summary updated successfully.",
          //   resp
          // );
          dispatch(getting_profile_successful(resp.data.client));
          // const store_value = store.getState();
          // let profileId = store_value.userInfo.user.profile_id;
          // window.location.href = `/user/profile/:${profileId}`
        } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
          dispatch(returnHome(resp.status));
        }
      })
      .catch((err) => console.log(err));
  };

export const saveAward = (award, secret) => async (dispatch) => {
  // make an update api call
  const obj = {
    Secret: secret,
    Award: award,
  };
  return fetch(baseURL + "/api/temp/initializeAward", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Initialize award updated successfully.", resp);
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log(err));
};

export const saveReference = (reference, secret) => async (dispatch) => {
  // make an update api call
  const obj = {
    Secret: secret,
    Reference: reference,
  };
  return fetch(baseURL + "/api/temp/initializeReference", {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Initialize reference updated successfully.", resp);
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => console.log(err));
};

// initialize end
export const cert_loading = () => ({
  type: prototype.CERT_LOADING,
});

export const cert_success = (cert) => ({
  type: prototype.CERT,
  payload: cert,
});

export const cert_function = (cert) => async (dispatch) => {
  dispatch(cert_success(cert));
};

export const lang_loading = () => ({
  type: prototype.LANG_LOADING,
});

export const lang_success = (lang) => ({
  type: prototype.LANG,
  payload: lang,
});
// resume language
export const lang_function = (cert) => async (dispatch) => {
  dispatch(lang_success(cert));
};

export const resume_step_add_function = () => ({
  type: prototype.RESUME_STEP_ADD,
});

export const resume_step_sub_function = () => ({
  type: prototype.RESUME_STEP_SUB,
});
export const resume_step_jump_function = (step) => ({
  type: prototype.RESUME_STEP_JUMP,
  payload: step,
});

// spanish
export const spanish_profile_loading = () => ({
  type: prototype.EMPLOYEE_SPANISH_PROFILE_LOADING,
});

export const spanish_profile_failed = (response) => ({
  type: prototype.EMPLOYEE_SPANISH_PROFILE_FAILED,
  payload: response,
});

export const spanish_profile_successful = (response) => ({
  type: prototype.EMPLOYEE_SPANISH_PROFILE,
  payload: response,
});


// Job posting redux functions start
export const JobDetailed = (JobDetails) => ({
  type: prototype.JOBDETAILS,
  payload: JobDetails,
});

export const JobDetails_loading = () => ({
  type: prototype.JOBDETAILS_LOADING,
});

// dispatch activities into the store
export const dispatchJobDetail = (JobDetails) => (dispatch) => {
  dispatch(JobDetailed(JobDetails));
};

// Job posting redux functions ends
export const available_jobs_loading = () => ({
  type: prototype.AVAILABLE_JOBS_LOADING,
});

export const available_jobs_err = (details) => ({
  type: prototype.AVAILABLE_JOBS_FAILED,
  payload: details,
});

export const available_jobs_success = (details) => ({
  type: prototype.AVAILABLE_JOBS,
  payload: details,
});

export const getAvailablejobs = (page) => async (dispatch) => {
  var obj = {
    page: page,
  };
  return fetch(baseURL + "/api/client/filterByResTitle", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("Available jobs ", resp);
      if (resp.success == true) {
        dispatch(available_jobs_success(resp?.data?.returned_value));
        dispatch(total_page(resp?.data?.page_num));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        dispatch(available_jobs_err(resp.data));
      }
    })
    .catch((err) => console.log(err));
};

// available jobs by category
// Job posting redux functions ends
export const available_jobs_loading_category = () => ({
  type: prototype.AVAILABLE_JOBS_LOADING_CATEGORY,
});

export const available_jobs_err_category = (details) => ({
  type: prototype.AVAILABLE_JOBS_FAILED_CATEGORY,
  payload: details,
});

export const available_jobs_success_category = (details) => ({
  type: prototype.AVAILABLE_JOBS_CATEGORY,
  payload: details,
});

// employee application function

export const application_loading = () => ({
  type: prototype.APPLICATION_LOADING,
});

export const application_error = (data) => ({
  type: prototype.APPLICATION_FAILED,
  payload: data,
});

export const application_successful = (data) => ({
  type: prototype.APPLICATION_SUCCESSFUL,
  payload: data,
});

export const ApplyForAJob = (jobName, resumeId, testAnswer, type) => async (dispatch) => {
  return fetch(baseURL + `/api/job/apply_for_job/${jobName}/${resumeId}/${type}`, {
    method: "PATCH",
    body: JSON.stringify({testAnswer: testAnswer}),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // do something
      if (resp.success === true) {
        dispatch(application_successful(resp.status));
        dispatch(jobs_applied(resp?.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log(resp);
        dispatch(application_error(resp?.message));
      }
    })
    .catch((err) => console.log("application error", err));
};

// get applicants of a particular job

export const jobs_applied_loading = () => ({
  type: prototype.JOBS_APPLIED_LOADING,
});

export const jobs_applied_failed = (data) => ({
  type: prototype.JOBS_APPLIED_FAILED,
  payload: data,
});

export const jobs_applied = (data) => ({
  type: prototype.JOBS_APPLIED,
  payload: data,
});

export const getJobsApplied = (page) => async (dispatch) => {
  return fetch(baseURL + `/api/client/get_all_my_jobs/${page}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("jobs applied result", resp);
        dispatch(jobs_applied(resp.data?.allJobs));
        dispatch(total_page(resp?.data?.page_num))
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("error resp", resp);
        dispatch(jobs_applied_failed(resp.status));
      }
    })
    .catch((err) => console.log("jobs applied getting func failed", err));
};

// get chat information

export const message_notification_loading = () => ({
  type: prototype.MESSAGE_NOTIFICATION_LOADING,
});

export const message_notification = (data) => ({
  type: prototype.MESSAGE_NOTIFICATION,
  payload: data,
});

export const chat_info_loading = () => ({
  type: prototype.CHAT_INFO_LOADING,
});

export const chat_info_failed = (data) => ({
  type: prototype.CHAT_INFO_FAILED,
  payload: data,
});

export const chat_info = (data) => ({
  type: prototype.CHAT_INFO,
  payload: data,
});

export const chat_info_set = (data) => ({
  type: prototype.CHAT_INFO,
  payload: data,
});

export const getChatInformation = (profileId) => async (dispatch) => {
  dispatch(chat_info_loading(true));
  return fetch(baseURL + `/api/chat/getChat/${profileId}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("chat information", resp);
        dispatch(chat_info(resp.data));
        let msg_notification = 0;
        // loop tru resp.data to get the number of message notifications
        for (let i = 0; i <= resp?.data.length - 1; i++) {
          if (resp?.data[i].Notification > 0) {
            msg_notification += 1;
          }
        }
        dispatch(message_notification(msg_notification)); // dispatch the overall notification.
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("error resp", resp);
        dispatch(chat_info_failed(resp.status));
      }
    })
    .catch((err) => console.log("chat information getting func failed", err));
};

export const getChatInfo = (profileId) => async (dispatch) => {
  dispatch(chat_info_loading(true));
  return fetch(baseURL + `/api/chat/getChat/${profileId}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("chat information", resp);
        dispatch(chat_info(resp.data));
      }
    })
    .catch((err) => console.log("chat information getting func failed", err));
 }

// get chat information wit a particular user

export const end_to_end_chat_loading = () => ({
  type: prototype.END_TO_END_CHAT_LOADING,
});

export const end_to_end_chat_failed = (data) => ({
  type: prototype.END_TO_END_CHAT_FAILED,
  payload: data,
});

export const end_to_end_chat = (data) => ({
  type: prototype.END_TO_END_CHAT,
  payload: data,
});

export const getEndToEndChatInformation =
  (employerId, employeeId) => async (dispatch) => {
    return fetch(baseURL + `/api/chat/getChatMessage/${employerId}/${employeeId}`, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
      credentials: "include"
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success === true) {
          // console.log("chat information", resp);
          dispatch(end_to_end_chat(resp.data));
        } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
          dispatch(returnHome(baseURL1));
        } else {
          // console.log("error resp", resp);
          dispatch(end_to_end_chat_failed(resp.status));
        }
      })
      .catch((err) =>
        console.log("chat information with another user func failed", err)
      );
  };

// get chat information

export const send_message_loading = () => ({
  type: prototype.SEND_MESSAGE_LOADING,
});

export const send_message_failed = (data) => ({
  type: prototype.SEND_MESSAGE_FAILED,
  payload: data,
});

export const send_message = (data) => ({
  type: prototype.SEND_MESSAGE,
  payload: data,
});

export const sendMessage = (chatId, senderId, message, profileId) => async (dispatch) => {
  dispatch(send_message_loading());
  const obj = {
    chatId: chatId,
    senderId: senderId,
    message: message
  }
  // console.log("obj", obj)
  return fetch(baseURL + `/api/chat/sendMessage`, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("chat information", resp);
        dispatch(send_message(resp.data));
        dispatch(end_to_end_chat(resp.data));
        // dispatch(getChatInfo(profileId));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("error resp", resp);
        dispatch(send_message_failed(resp.status));
      }
    })
    .catch((err) =>
      console.log("sending message to another user func failed", err)
    );
};

// save a job that was posted

export const save_job_loading = () => ({
  type: prototype.SAVE_JOB_LOADING,
});

export const save_job__failed = (data) => ({
  type: prototype.SAVE_JOB_FAILED,
  payload: data,
});

export const save_job_ = (data) => ({
  type: prototype.SAVE_JOB,
  payload: data,
});

export const saveJob = (jobId) => async (dispatch) => {
  dispatch(save_job_loading());
  return fetch(baseURL + `/api/post/save_post?jobId=${jobId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(save_job_(resp.status));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("saving job failed", resp);
        dispatch(save_job__failed(resp.data));
      }
    })
    .catch((err) =>
      console.log("An error occured while saving jobs", err)
    );
};

// get saved jobs by a user

export const get_save_jobs_loading = () => ({
  type: prototype.GET_SAVED_JOBS_LOADING,
});

export const get_saved_jobs__failed = (data) => ({
  type: prototype.GET_SAVED_JOBS_FAILED,
  payload: data,
});

export const get_saved_jobs = (data) => ({
  type: prototype.GET_SAVED_JOBS,
  payload: data,
});

export const getSavedJobs = () => async (dispatch) => {
  return fetch(baseURL + `/api/post/my_saved_post`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("saved jobs", resp);
      if (resp.success === true) {
        // console.log("saved jobs", resp);
        dispatch(get_saved_jobs(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("error resp", resp);
        dispatch(get_saved_jobs__failed(resp.status));
      }
    })
    .catch((err) => console.log("getting saving jobs func failed", err));
};


// establish a chat
export const establishChat = (employerId, employeeId, JobTitle, resumeId, jobId) => async (dispatch) => {
  const obj = {
    employerId: employerId,
    employeeId: employeeId,
    JobTitle: JobTitle,
    resumeId: resumeId,
    jobId: jobId
  }
  // console.log("obj", obj);
  return fetch(baseURL + "/api/chat/establish", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  }).then(resp => resp.json())
    .then(resp => {
      if (resp.success === true) {
        // console.log("Chat established successfully", resp);
        // if ( resp.data !== null ) {
        //   dispatch(chat_info(resp.data));
        // }
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
}


// send decision card for employers

export const decision_loading = () => ({
  type: prototype.DECISION_CARD_LOADING,
});

export const decision_failed = (data) => ({
  type: prototype.DECISION_CARD_FAILED,
  payload: data,
});

export const decision_card = (data) => ({
  type: prototype.DECISION_CARD,
  payload: data,
});

export const getDecisionCard = (decisionCardId) => async (dispatch) => {
  return fetch(baseURL + "/api/decision/getDecisionCard/" + decisionCardId, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  }).then(resp => resp.json())
    .then(resp => {
      // console.log("Decision card initialized successfully", resp);
      if (resp.success === true) {
        (dispatch(decision_card(resp.data)));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
}


export const AcceptDecisionCard = (cred) => async (dispatch) => {
  const obj = {
    decisionCardId: cred.decisionCardId,
    employeeId: cred.employeeId
  }
  // console.log("obj", obj)
  return fetch(baseURL + "/api/decision/accept", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  }).then(resp => resp.json())
    .then(resp => {
      // console.log("Decision card initialized successfully", resp);
      if (resp.success === true) {
        (dispatch(decision_card(resp.data)))
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    })
}

export const notification_loading = () => ({
  type: prototype.NOTIFICATION_LOADING,
});

export const notification_success = (data) => ({
  type: prototype.NOTIFICATION,
  payload: data,
});

export const getNotification = (employerId) => async (dispatch) => {
  dispatch(notification_loading(true));
  return fetch(baseURL + "/api/job/jobNotification", {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  }).then(resp => resp.json())
    .then(resp => {
      // console.log("Job notification has arrive", resp);
      if (resp.success === true) {
        (dispatch(notification_success(resp.data)))
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      }
    }).catch(err => console.log("error", err));
}
// Send verification otp

// save a job that was posted

export const verify_otp_loading = () => ({
  type: prototype.PHONE_VERIFICATION_LOADING,
});

export const verify_otp_failed = (data) => ({
  type: prototype.PHONE_VERIFICATION_FAILED,
  payload: data,
});

export const verify_otp = (data) => ({
  type: prototype.PHONE_VERIFICATION,
  payload: data,
});

export const verifyOtp = (code, phoneNumber) => async (dispatch) => {
  dispatch(verify_otp_loading());
  const obj = {
    token: code,
    phone_number: phoneNumber
  }
  return fetch(baseURL + `/api/users/phone/verify_otp`, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Verification successful", resp)
        dispatch(userInfo(resp?.data));
        dispatch(verify_otp(resp.status));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("otp verification failed", resp);
        dispatch(verify_otp_failed(resp.message));
      }
    })
    .catch((err) =>
      console.log("An error occured while verifying otp", err)
    );
};


// sending of otp

export const send_otp_loading = () => ({
  type: prototype.SEND_OTP_LOADING,
});

export const send_otp_failed = (data) => ({
  type: prototype.SEND_OTP_FAILED,
  payload: data,
});

export const send_otp = (data) => ({
  type: prototype.SEND_OTP,
  payload: data,
});

export const sendOtp = (phoneNumber) => async (dispatch) => {
  dispatch(send_otp_loading());
  const obj = {
    phone_number: phoneNumber
  }
  return fetch(baseURL + `/api/users/phone/send_otp`, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(send_otp(resp.status));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("send otp failed", resp);
        dispatch(send_otp_failed(resp.message));
      }
    })
    .catch((err) =>
      console.log("An error occured while sending otp", err)
    );
};


// get a job

export const get_a_job_loading = () => ({
  type: prototype.GET_A_JOB_LOADING,
});

export const get_a_job_failed = (data) => ({
  type: prototype.GET_A_JOB_FAILED,
  payload: data,
});

export const get_a_job = (data) => ({
  type: prototype.GET_A_JOB,
  payload: data,
});


export const getAjob = ({ jobId }) => async (dispatch) => {
  return fetch(baseURL + `/api/job/getAjob/${jobId}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(get_a_job(resp.data));
      } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "No token provided")) {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("Job not gotten successfully", resp);
        dispatch(get_a_job_failed(resp.message));
      }
    })
    .catch((err) =>
      console.log("An error occured while sending otp", err)
    );
}

// add an employee to the list of job applicants

export const application_public_loading = () => ({
  type: prototype.APPLICATION_PUBLIC_LOADING,
});

export const application_public_error = (data) => ({
  type: prototype.APPLICATION_PUBLIC_FAILED,
  payload: data,
});

export const application_public_successful = (data) => ({
  type: prototype.APPLICATION_PUBLIC_SUCCESSFUL,
  payload: data,
});

export const ApplyForAJobPublic = (jobName, resumeId, userId) => async (dispatch) => {
  dispatch(application_public_loading(true));
  return fetch(baseURL + `/api/job/apply_for_job_public/${jobName}/${userId}/${resumeId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // do something
      if (resp.success === true) {
        dispatch(application_public_successful(resp.status));
      } else {
        // console.log(resp);
        dispatch(application_public_error(resp?.message));
      }
    })
    .catch((err) => console.log("application error", err));
};

export const createReferralLinkLoading = () => ({
  type: prototype.CREATE_REFERRAL_LINK_LOADING,
});

export const createReferralLinkSuccess = (referralLink) => ({
  type: prototype.CREATE_REFERRAL_LINK_SUCCESS,
  payload: referralLink,
});

export const createReferralLinkFailed = (error) => ({
  type: prototype.CREATE_REFERRAL_LINK_FAILED,
  payload: error,
});



export const createReferralLink = (userId) => async (dispatch) => {
  dispatch(createReferralLinkLoading());

  return fetch(baseURL + `/api/referral/create/${userId}`, { // check this out some other time.
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // do something
      if (resp.success === true) {
        dispatch(createReferralLinkSuccess(resp.status));
      } else {
        dispatch(createReferralLinkFailed(resp?.message));
      }
    })
    .catch((err) => console.log("application error", err));
}


export const onlineUsersClear = () => ({
  type: prototype.ONLINE_LOADING,
});

export const onlineUsers = (users) => ({
  type: prototype.ONLINE,
  payload: users,
});

//SEARCH loading dispatching function
export const search_loading = () => ({
  type: prototype.SEARCH_LOADING,
});

// SEARCH failed dispatching function
export const search_failed = (response) => ({
  type: prototype.SEARCH_FAILED,
  payload: response,
});

// search dispatching function
export const search_success = (response) => ({
  type: prototype.SEARCH,
  payload: response,
});

//SEARCH loading dispatching function
export const Dashboard_only_loading = () => ({
  type: prototype.SET_DASHBOARD_ONLY_LOADING,
});

export const Dashboard_only = (response) => ({
  type: prototype.SET_DASHBOARD_ONLY,
  payload: response,
});

export const SidebarOpen = () => ({
  type: prototype.SIDEBAR,
})
