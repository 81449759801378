import React, { useState, useEffect } from "react";
import BackButton from "../common/back_button";
import { ToastContainer, toast } from "react-toastify";
import InterviewModal from "../common/interviewModal";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { saveIntoServerCache, getOneFromServerCache } from "../../utils/saveIntoCache"
import {
  getAvailablejobs,
  getting_employer_profile_loading,
  ApplyForAJob,
  application_loading,
  getJobsApplied,
  application_error,
  saveJob,
  save_job_loading,
  getAjob,
  save_link_loading,
  getProfile,
  postUserProfile,
  getAllMyCv,
  userName_from_db,
  login_success,
  returnHome
} from "../../Redux/ActionCreator";
import NoResumeModal from "../gamified_modals/no_resume_modal";
import { baseURL } from "../../Redux/baseURL";

const SkillTest = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userName = useSelector((prev) => prev.userInfo);
  const my_name = userName.user;

  const [questionNumber, setQuestionNumber] = useState(0);
  const [OpenInterviewModal, setOpenInterviewModal] = useState(false);
  const [completed, setCompleted] = useState(false);
  const save_cv = useSelector((e) => e.saved_cv?.message);
  const available_jobs = useSelector((e) => e.available_jobs); const available_jobs_category = useSelector(e => e.available_jobs_category);
  const jobs_catgory = available_jobs_category.message;
  let searchJobs = useSelector(e => e.searchResult.message);
  const [Job, setJob] = useState(null);
  let [jobArray, setJobArray] = useState([]);
  const [percent, setPercent] = useState(0);

  let [answersToQuestions, setAnswersToQuestions] = useState([]);

  const jobName = useParams().jobName;

  // let jobName = window.location.pathname.split("/")[3];
  // console.log("jobName in skill test file", jobName);

  const getASecureJobFromDb = async () => {
    try {
      fetch(baseURL + `/api/job/getASecurejob/${jobName}`, {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include"
      })
        .then((resp) => resp.json())
        .then(async resp => {
          //  console.log("resp", resp);
          if (resp.success == true && resp.statusCode == 200) {
            if (resp.data.skillTest !== null) {
              resp.data.skillTest = [resp.data?.skillTest]
            }
            setJob(resp?.data);
            // add resp.data to list of available jobs
            // await saveIntoServerCache("JobOwnerId", resp.data); //save the job into cache
            return;
          } else if (resp.success === false && resp.message == "Unauthorized") {
            dispatch(returnHome(resp?.status));
          } else {
            if (resp.title == "Not Found") {
              setTimeout(() => {
                toast.error("Job not found.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              navigate("/user/dashboard");
            }
          }
        }).catch(err => console.log("getting job error", err))

    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    // filter out the job with this jobName from the available jobs
    // if not in the available jobs, then get from the db thru the server

    let allPossibleJobs = [...(available_jobs?.message || []), ...(jobs_catgory || []), ...(searchJobs || [])];
    let jobArray = allPossibleJobs?.filter(item => item.custom_name === jobName);
    if (jobArray !== undefined && jobArray?.length > 0) {
      setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
    } else {
      // get the job from the database.
      getASecureJobFromDb();
    }

    // jobArray = available_jobs.message?.filter(
    //     (item) => item.custom_name === jobName
    // );
    // console.log("JobArray <===:>", jobArray);
    // if (jobArray !== undefined && jobArray.length > 0) {
    //     setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
    // } else {
    //     // cehck if it is in jobs_catgory
    //     jobArray = jobs_catgory?.filter(
    //         (item) => item.custom_name === jobName
    //     );

    //     if (jobArray !== undefined && jobArray.length > 0) {
    //         setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
    //     } else {
    //         // else check it in the searchjobs
    //         jobArray = searchJobs?.filter(
    //             (item) => item.custom_name === jobName
    //         );
    //         console.log("searchJobs", searchJobs)
    //         if (jobArray !== undefined && jobArray.length > 0) {
    //             setJob(jobArray[0]?.JobId !== undefined ? jobArray[0]?.JobId : jobArray[0]);
    //         } else {
    //             // get the job from the database.
    //             getASecureJobFromDb();
    //         }
    //     }
    // }

    if (save_cv.length == 0 || save_cv == null) {
      // get the user's cv
      dispatch(getAllMyCv());
    }
  }, []);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (answersToQuestions?.length == 0) {
      let ans = getOneFromServerCache("answersTo-" + jobName);
      ans.then(res => res.json())
        .then(res => {
          if (res.data !== null) {
            setAnswersToQuestions(JSON.parse(res.data));
          }
        }).catch(err => console.log("error getting answers to questions", err));
    }
  }, [answersToQuestions]);

  const [totalPage, setTotalPage] = useState(1);

  const handlePreviousQuestion = () => {
    if (totalPage == 2) {
      setTotalPage(totalPage - 1);
      setCount(0);
    }
  }

  const update_application = async () => {
    try {
      return fetch(baseURL + "/api/job/update_application", {
        method: "PATCH",
        body: JSON.stringify({
          jobId: Job?._id,
          testAnswer: answersToQuestions
        }),
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include"
      }).then(resp => resp.json())
        .then(res => {
          //  console.log("job skilltest answered successfully", res);
          navigate("/user/jobs")
        })
        .catch(err => console.log("job skilltest answering error", err))
    } catch (error) {
      // console.log("skill test answering error", error);
    }
  }

  const handleNextQuestion = async () => {
    if (totalPage == 1) {
      if (answersToQuestions.length < 5) {
        setTimeout(() => {
          toast.error("Please answer all question before proceeding.", {
            position: toast.POSITION.TOP_CENTER,
          });
        }, 50);
      } else {
        setCount(5);
        setTotalPage(totalPage + 1);
        // setQuestionNumber(questionNumber + 1);
        setPercent(answersToQuestions.length * 10);
      }
    } else {
      if (answersToQuestions.length < 10) {
        setTimeout(() => {
          toast.error("Please answer all question before proceeding.", {
            position: toast.POSITION.TOP_CENTER,
          });
        }, 50);
      } else if (answersToQuestions.length == 10) {
        await update_application();
      }
    }
  }

  const handleOptionChange = async (e, index) => {
    try {
      //    console.log("index", index);
      let answer = e.target.value;
      // answersToQuestions[index] = {
      //     question: Job?.skillTest[0]?.skillTestQuestions[index].question,
      //     answer: answer
      // }
      // answersToQuestions = [ ...answersToQuestions];
      // setAnswersToQuestions(answersToQuestions);
      let actualvalue = answer?.split("&&&")[0];
      let question = answer?.split("&&&")[1];
      if (answersToQuestions?.length == 0) {
        answersToQuestions.push({
          question: question,
          answer: actualvalue
        })
      } else {
        // try to check if the question is already in the array
        let isQuestionAlreadyInArray = answersToQuestions?.find(e => e.question == question);
        if (isQuestionAlreadyInArray) {
          isQuestionAlreadyInArray.answer = actualvalue;
        } else {
          answersToQuestions.push({
            question: question,
            answer: actualvalue
          })
        }
      }
      answersToQuestions = [...answersToQuestions];
      setAnswersToQuestions(answersToQuestions);

      // save into cache
      let key = "answersTo-" + jobName;
      await saveIntoServerCache(key, answersToQuestions);
      //   console.log("Answers to questions", answersToQuestions);
    } catch (error) {
      throw error;
    }
  }

  //  console.log("Answers to questions", answersToQuestions);

  // const ApplyModal = completed && (
  //     <div>
  //         {save_cv?.length === 0 ? <NoResumeModal OpenInterviewModal={completed}
  //             Closemodal={() => setCompleted(false)} profileLink={`/user/profile/:${my_name?.profile_id}`} /> :
  //             <InterviewModal
  //             OpenInterviewModal={completed}
  //             Closemodal={() => setCompleted(false)}
  //             indeed={Job}
  //             purpose="job"
  //             testAnswer={answersToQuestions}
  //         />}
  //     </div>
  // )


  return (
    <>
      <div className=" h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center">
        <div className="px-6 lg:px-0 relative w-full lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">
          <BackButton onClick={() => navigate(`/jobs/${jobName}`)} text="Back to jobs" />
          <div>
            <h3 className="text-[#5843BD] font-semibold text-md vsm:text-sm">Basic Skill Test</h3>
            <h4 className="text-[#22263A] font-normal text-md vsm:text-sm ">{Job?.jobTitle}</h4>
          </div>

          <div className="mt-2">
            <div className="p-2 md:p-4 bg-[#5843BD] rounded-tl-lg rounded-tr-lg flex items-center">
              <h4 className="md:text-lg vsm:test-sm text-white font-medium ">
                Percentage Completed: <span className="font-semibold">{percent}%</span>
              </h4>
              <h4 className="md:text-lg vsm:text-sm text-white font-medium ml-auto">({count + 1} 0f 10)</h4>
            </div>

            {Job?.skillTest[0]?.skillTestQuestions?.slice(count, totalPage == 1 ? 5 : 10)?.map((e, ind) => {
              return (
                <div className="border-r-[1px] border-l-[1px] border-b-[1px] p-4 rounded-br-lg rounded-bl-lg" >
                  <h3 className="text-[#4E5161] font-semibold md:text-lg vsm:text-sm">{e.question}</h3>

                  <div className=' flex-col gap-y-6'>
                    {e?.options?.map((option, i) => (
                      <div key={i} className='flex items-center gap-x-2 my-2'>
                        <input type='radio' id={`option_${ind}-${i}`} className='w-6 h-6 accent-red-500 border-2 border-[#5843BD] focus:ring-transparent accent-purple focus:ring-[#5843BD]'
                          name={`option_${Job?.skillTest[0]?.skillTestQuestions.indexOf(e)}`}
                          checked={answersToQuestions?.find(element => element.question == e.question)?.answer == option}
                          onChange={(e) => handleOptionChange(e, Job?.skillTest[0]?.skillTestQuestions.indexOf(e))} value={`${option}&&&${e.question}`}
                        />
                        <label for={`option_${ind}-${i}`} className='text-[#22263A]'>{option}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )
            })}
            <div className="self-stretch sm:justify-between sm:items-center gap-4 sm:flex">
              <button
                className="px-3 xsm:px-5 text-sm sm:text-base py-3 justify-center items-center gap-2.5 flex vsm:w-full vsm:my-2 sm:w-fit shadow border-[1px] border-[#FFECE5] rounded-lg"
                onClick={handlePreviousQuestion}
              >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8L17.43 9.393L11.85 15H24V17H11.85L17.43 22.573L16 24L8 16L16 8Z" fill="#FF6633" />
                  <path d="M16 30C18.7689 30 21.4757 29.1789 23.778 27.6406C26.0803 26.1022 27.8747 23.9157 28.9343 21.3576C29.9939 18.7994 30.2712 15.9845 29.731 13.2687C29.1908 10.553 27.8574 8.05845 25.8995 6.10051C23.9416 4.14258 21.447 2.80921 18.7313 2.26901C16.0155 1.72882 13.2006 2.00607 10.6424 3.06569C8.08426 4.12532 5.89776 5.91973 4.35942 8.22202C2.82108 10.5243 1.99999 13.2311 1.99999 16C2.00423 19.7117 3.48059 23.2702 6.10518 25.8948C8.72977 28.5194 12.2883 29.9958 16 30ZM16 4.00001C18.3734 4.00001 20.6934 4.70379 22.6668 6.02237C24.6402 7.34095 26.1783 9.21509 27.0865 11.4078C27.9948 13.6005 28.2324 16.0133 27.7694 18.3411C27.3064 20.6689 26.1635 22.8071 24.4853 24.4853C22.807 26.1635 20.6688 27.3064 18.3411 27.7694C16.0133 28.2325 13.6005 27.9948 11.4078 27.0866C9.21508 26.1783 7.34094 24.6402 6.02236 22.6668C4.70378 20.6935 3.99999 18.3734 3.99999 16C4.0037 12.8185 5.26917 9.76845 7.5188 7.51882C9.76844 5.26918 12.8185 4.00371 16 4.00001Z" fill="#FF6633" />
                </svg>
                <span className="text-center text-[#FF6633] text-sm sm:text-base font-semibold">
                  Previous Question
                </span>
              </button>

              <button
                className="px-3 xsm:px-5 py-3 text-sm sm:text-base bg-indigo-700 rounded-lg shadow justify-center gap-2.5 flex items-center vsm:w-full vsm:my-2 sm:w-fit"
                onClick={handleNextQuestion}
              >
                <span className="text-center text-white text-sm sm:text-base font-semibold whitespace-nowrap">
                  <>
                    Save and Continue
                  </>
                </span>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                  <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {ApplyModal} */}
      <ToastContainer />
    </>
  )
}

export default SkillTest;