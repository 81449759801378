import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
// protected route component
import ProtectedRoute from "./middleware/Auth/protectedRoute";
import LoaderModal from "./components/common/LoaderModal";

import HomeDashboard from "./views/layout/HomeDashboard.jsx";
import UserDashboard from "./components/Dashboard/UserDashboard";
import MoilUserDashboard from "./components/Dashboard/moil_user_dashboard.jsx"
import Logout from "./components/Logout/logout";
import UserResumeForm from "./components/Resume/moil_user_resume";
import { useSelector, useDispatch } from "react-redux";
import UserResumeType from "./components/Resume/screens/moil_user_resume_type";
import WorkerProfile from "./components/Profile/newProfile";
import UserJobType from "./components/Resume/screens/moil_user_job_type";
import UserSKillsAndLicensing from "./components/Resume/screens/moil_user_license";
import UserEducation from "./components/Resume/screens/moil_user_education";
import UserWorkExperience from "./components/Resume/screens/moil_user_work";
import UserReference from "./components/Resume/screens/moil_user_reference";
import ProfilePhoto from "./components/Image";
import PublicJobDetails from "./components/Job/publicJobDetails.jsx"

import ProfileProtected from "./middleware/Auth/profileProtected";
import EmployeesJobCreated from "./components/Job/employees_view_job_created";
import EmployeeJobs from "./components/Job/jobs";
import UserMessages from "./components/Message/message";
import UserPreference from "./components/Resume/screens/moil_user_preference";
import SearchJobField from "./components/common/SearchBars/SearchJobField";
import PageTrackingHOC from "./middleware/Auth/pageTrackingHOC";
import Register from "./components/Register/index.jsx";
import Login from "./components/login/index.jsx";
import Forgot from "./components/authPage/Forgot";
import AuthPage from "./components/authPage";
import Reset from "./components/authPage/Reset"
import { AnalyticsProviderContext } from "./context/AnalyticsContext";
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import NewUploadModal from "./components/common/upload/uploadModal";
import Resume from "./components/Resume/MyResume/Resume"
import UserMessagesUser from "./components/Message";
import UserJobDetails from "./components/Job/index"
import EmployerWorkerViewOriginal from "./components/Profile/EmployerProfile/employer_workerview_profile.jsx"
import { resume_step_jump_function } from "./Redux/ActionCreator.js";
import NotAvailable from "./components/common/not_available";
import { startTokenRefreshInterval } from "./utils/auth-token/refreshToken";
// import NotFoundPage from "./components/error_page"

const UserResume = lazy(() => import("./components/Resume/screens/moil_user_resume"));
const UserPreviewDetails = lazy(() => import("./components/Resume/screens/moil_user_preview"));
// const EmployerProfileView = lazy(() => import("./components/Profile/worker_employerView_profile"))
const Sidebar = lazy(() => import("./components/sidebar/sidebar"));
// const Resume = lazy(() => import("./components/Resume/MyResume/Resume"));
// const UserJobDetails = lazy(() => import("./components/Job/index"));
// const EmployerWorkerViewOriginal = lazy(() => import("./components/Profile/EmployerProfile/employer_workerview_profile.jsx"));
const ShareProfile = lazy(() =>
  import("./components/shareProfile")
);
// const UserMessagesUser = lazy(() => import("./components/Message"));
const WorkerDecisionCard = lazy(() => import("./components/DecisionCard/workerDecisionCard"));
import DownloadResumeModal from "./components/Resume/DownloadResume"
import NotFoundPage from "./components/error_page/index.jsx";
import { ToastContainer, toast } from "react-toastify";
import QRCodeGenerator from "./components/QRcode/qrcode.jsx";
import SkillTest from "./components/skillTest/index.jsx";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile_state = useSelector((prev) => prev.login);
  const lastPage = useSelector(e => e.lastPage);
  const userInfo = useSelector((e) => e.userInfo);
  let token = localStorage.getItem("token");
  
  useEffect(() => {
    let intervalId;

    if (profile_state.isAuthenticated) {
      // Start the token refresh interval
      intervalId = startTokenRefreshInterval();
    }

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [profile_state.isAuthenticated]);


  const analytics = Analytics({
    app: 'Moilapp',
    plugins: [
      segmentPlugin({
        writeKey: '5ohjq1GtYDnbs28HuF4kYpFRe6XHiJ2S',
      }),
    ],
  });

  analytics.page();

  const [bool, setBool] = useState(false);
  const location = useLocation();

  const handlejump = (step) => {
    dispatch(resume_step_jump_function(step));
  }


  // console.log("bool", bool);
  return (
    <AnalyticsProviderContext analytics={analytics}>
      <div className="app">
        <main className="content">
          <Suspense
            fallback={<LoaderModal text="Our AI is loading your data" />}
          >
            <Routes>
              <Route path="/" element={<Login />} />
              {/* <Route
                path="/profile/beta/*"
                element={
                  <ProfileProtected>
                    <PageTrackingHOC>
                      <EmployerProfileView />
                    </PageTrackingHOC>
                  </ProfileProtected>
                }
              /> */}
              {/* User route start */}
              <Route
                path="/user"
                element={
                  // <ProtectedRoute>
                  <HomeDashboard />
                  // </ProtectedRoute>
                }
              >
                <Route
                  path="jobs"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployeeJobs />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="messages"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <UserMessages />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="messages/:receiverId"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <UserMessagesUser />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="messages/:receiverId/decisionCard/:cardId"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <WorkerDecisionCard />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="profile/:profile_id"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <WorkerProfile />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                {/* New path added by BigSam  */}
                <Route
                  path="welcome"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <UserDashboard />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="dashboard"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <MoilUserDashboard />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="createProfile"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <UserResume />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":profile_id/share"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <ShareProfile />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="employer/profile/*"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployerWorkerViewOriginal />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="job_preview/:jobName"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <UserJobDetails />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="available_jobs"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <EmployeesJobCreated />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
                <Route 
                  path="job/skillTest/:jobName"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <SkillTest />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
              </Route>

              {/* User route ends */}

              {/* General route start */}
              {
                profile_state?.isAuthenticated == true ?
                  <Route
                    path="/jobs"
                    element={
                      // <ProtectedRoute>
                      <HomeDashboard />
                      // </ProtectedRoute>
                    }
                  >
                    <Route
                      path=":jobName"
                      element={
                        // <ProtectedRoute>
                        <UserJobDetails />
                        // </ProtectedRoute>
                      }
                    />
                  </Route> :
                  <Route
                    path="jobs/:jobName"
                    element={
                      // <ProtectedRoute>
                      <PublicJobDetails />
                      // </ProtectedRoute>
                    }
                  />
              }
              <Route path="/register" element={
                <Register />} />
              <Route path="/login" element={
                <Login />
              } />
              <Route path="/authenticate" element={
                <AuthPage />}>
                <Route path="forgot" index element={
                  <Forgot />
                } />
                <Route path="reset" element={
                  <Reset />} />
              </Route>
              <Route
                path="/user/jobs/search/:jobId"
                element={
                  <ProfileProtected>
                    <PageTrackingHOC>
                      <UserJobDetails />
                    </PageTrackingHOC>
                  </ProfileProtected>
                }
              />
              <Route
                path="user/profile/update/personalInfo"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserPreference />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="user/profile/update/about"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserJobType handlejump={handlejump} />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />

              <Route
                path="user/profile/update/skills"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserSKillsAndLicensing />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />

              <Route
                path="user/profile/update/education"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserEducation />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="user/profile/update/experience"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserWorkExperience />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="user/profile/update/reference"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserReference />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="update/*"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <ProfilePhoto />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  // <ProtectedRoute>
                  <HomeDashboard />
                  // </ProtectedRoute>
                }
              >
                <Route
                  path="resume"
                  element={
                    <ProtectedRoute>
                      <PageTrackingHOC>
                        <Resume />
                      </PageTrackingHOC>
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="/logout"
                element={
                  <ProtectedRoute>
                    <Logout />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/generateCV"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserResumeType />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/allCv"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserPreviewDetails />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/payment"
                element={
                  <ProtectedRoute>
                    <StripeComponent />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/upload"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <NewUploadModal />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                  // UploadModal
                }
              />
              <Route
                path="/resume/:cvID"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserPreviewDetails />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/form//*"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserResumeForm />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/form/:id/*"
                element={
                  <ProtectedRoute>
                    <PageTrackingHOC>
                      <UserResumeForm />
                    </PageTrackingHOC>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/moilQR/:profile_id"
                element={
                  <QRCodeGenerator />
                }
              />
              <Route
                path="/unavailable"
                element={
                  <NotAvailable />
                }
              />

              <Route
                path="/test"
                element={
                  <SkillTest />
                }
              />

              {/* General route ends */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </main>
        <ToastContainer />
      </div>
    </AnalyticsProviderContext>
  );
}

export default App;
